import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from '@pages/Home';
import { CustomFooter, CustomHeader } from '@pages/Shared';
import About from '@pages/About';
import Products from '@pages/Products';
import Services from '@pages/Services';
import Contact from '@pages/Contact';
import { Layout } from 'antd';

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <CustomHeader />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <CustomFooter />
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
