import { Card, Layout } from 'antd';
import {Typography} from 'antd';
import React from 'react';
import './products.css';


const { Title, Text } = Typography;
const { Content } = Layout;

function Products() {
  return (
    <Content>
      <div className="products">
        <h2>Products</h2>
        <ul>
          <li>
         
          <Card className="product-card" hoverable>
              <Title className="product-card__title" level={3}>EduBricks</Title>
              <Text className="product-card__description">Get the best LMS for your company with EduBricks, a comprehensive learning management system solution.</Text>
            </Card>
          </li>
          <li>
            <h3>Product 2</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </li>
          <li>
            <h3>Product 3</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </li>
        </ul>
      </div>
    </Content>
  );
}

export default Products;
