import React from 'react';
import { Layout, Typography, Row, Col, Button, Space } from 'antd';
import headerImage from '@assets/Images/header_image.jpeg';
import './main.css';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;

function Main() {
  return (
    <Content>
      <div className="image-container">

        <img src={headerImage} alt="Teaming" />
        <div className="gradient-overlay"></div>
        <h1 className="page-title">Gila Dynamics</h1>
        <h2 className="page-subtitle">
          Crafting Your Company's Digital Journey
        </h2>
      </div>
      <Space direction="vertical" size={["small", "large"]} style={{ padding: '20px', display: 'flex' }}>
        <Row>
          <Col span={24}>
            <div className="company-intro">
              <h1>We build custom software solutions<br />that help you achieve your goals</h1>
              <p>
                At Gila Dynamics, we take pride in delivering high-quality software solutions that are designed to improve the performance of your organization. Our team of talented developers and designers work closely with you to understand your unique requirements and develop custom software solutions tailored to meet your specific needs. With our extensive experience in the industry, we can help you transform your business for the digital age and stay ahead of the competition. Trust us to be your partner in achieving your business goals and unlocking your company's full potential.
              </p>
              <Link to="/about">
                <Button type="primary" size="large">
                  Learn More
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="why-choose">
              <Title level={2}>Why Choose Gila Dynamics?</Title>
              <ul>
                <li>
                  Custom software solutions tailored to meet your organization's unique needs
                </li>
                <li>Experienced and passionate team of developers and designers</li>
                <li>Reliable, efficient, and high-quality software solutions</li>
                <li>
                  Constantly looking for new and innovative ways to help our clients stay ahead of the competition
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="quote-container">
              <p className="quote-text">
                "Passionate about using technology to help organizations achieve their goals"
              </p>
              <p className="quote-source">- Gila Dynamics</p>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Card>
              <Title level={3}>Ready to Get Started?</Title>
              <Text>
                Contact us today to learn more about how Gila Dynamics can help your organization take advantage of the latest technologies and improve its performance with custom software solutions.
              </Text>
              <br />
              <Button type="primary" size="large" style={{ marginTop: '20px' }}>
                Get in Touch
              </Button>
            </Card>
          </Col>
        </Row> */}
      </Space>
    </Content>
  );
}

export default Main;
