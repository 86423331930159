import { Link } from 'react-router-dom';
import './header.css';
import { Layout, Menu, theme } from 'antd';
import logo from "@assets/Images/logo.png";
const { useToken } = theme;

const { Header } = Layout;

function CustomHeader() {
  let { token } = useToken() as any
  console.log(token)

  return (
    <Header style={{padding: '0px'}} className='header'>
      <div className="logo-container">
        <img src={logo} style={{ borderRadius: '50%'}} alt="My Business Logo" />
      </div>
      <Menu style={{backgroundColor: 'transparent', color: '#fff'}} mode='horizontal' defaultSelectedKeys={['1']}>
        <Menu.Item key='1'><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key='2'><Link to="/about">About Us</Link></Menu.Item>
        {/* <Menu.Item key='3'><Link to="/products">Products</Link></Menu.Item>
        <Menu.Item key='4'><Link to="/services">Services</Link></Menu.Item>
        <Menu.Item key='5'><Link to="/contact">Contact Us</Link></Menu.Item> */}
      </Menu>
    </Header>

  );
}

export default CustomHeader;
