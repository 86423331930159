import { Layout } from 'antd';
import React from 'react';
import './services.css';


const { Content } = Layout;

function Services() {
  return (
    <Content>
      <div className="page">
        <h1>Our Services</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius pretium mi, in aliquam magna. Praesent condimentum nisi quis commodo varius.</p>
        <ul>
          <li>Service 1</li>
          <li>Service 2</li>
          <li>Service 3</li>
        </ul>
      </div>
    </Content>
  );
}

export default Services;
