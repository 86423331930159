import { Layout } from 'antd';
import React from 'react';
import './contacts.css';

const { Content } = Layout;

function Contacts() {
  return (
    <Content>
      <div className="page">
        <h1>Contact Us</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius pretium mi, in aliquam magna. Praesent condimentum nisi quis commodo varius.</p>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message"></textarea>
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
    </Content>
  );
}

export default Contacts;
