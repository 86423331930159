import { Layout } from 'antd';
import './about.css';

const { Content } = Layout;

function About() {
  return (
    <Layout>
      <Content>
        <div className="about">
          <h2>About Us</h2>
          <p>Welcome to Gila Dynamics - a software solutions provider dedicated to helping organizations achieve their goals through innovative technology.</p>

          <p>At Gila Dynamics, we were founded by Robert Al Malak and Sam Morton with a commitment to providing our clients with the best possible software solutions. With years of experience in the industry, we know what it takes to deliver high-quality products that meet the unique needs of each organization we work with.</p>

          <p>Our team of talented developers and designers are committed to staying ahead of the curve when it comes to the latest technologies and trends. This means we're always looking for new ways to help our clients achieve success and stay ahead of the competition.</p>

          <p>At Gila Dynamics, we believe that our success is directly tied to the success of our clients. That's why we work tirelessly to ensure that our products and services meet the highest standards of quality, reliability, and performance.</p>

          <p>Whether you're looking for a custom software solution or need help optimizing your existing technology, we're here to help. Contact us today to learn more about what we can do for your organization.</p>
        </div>
      </Content>
    </Layout>
  );
}

export default About;
