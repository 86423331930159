import React from 'react';
import './footer.css';
import { Layout } from 'antd';

const { Footer } = Layout;

function CustomFooter() {
  return (
      <Footer>© 2023 Gila Dynamics, LLC</Footer>
  );
}

export default CustomFooter;
